
import 'regenerator-runtime/runtime';
// @ts-ignore
import Ws from "@adonisjs/websocket-client";
import Swal from "sweetalert2";
import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {
    balance: 0
  },
  getters: {
    // currentBreakPoint: state => {
    //   const { windowWidth } = state
    //   if (windowWidth >= $themeBreakpoints.xl) return 'xl'
    //   if (windowWidth >= $themeBreakpoints.lg) return 'lg'
    //   if (windowWidth >= $themeBreakpoints.md) return 'md'
    //   if (windowWidth >= $themeBreakpoints.sm) return 'sm'
    //   return 'xs'
    // },
  },
  mutations: {
    setBalance(state, val) {
      state.balance = val
    }
  },
  actions: {
    ws({ commit }) {
      const Token = localStorage.getItem("token");

      if (Token) {
        // console.log("ConnectWS");
        const ws = Ws(`wss://${localStorage.getItem('wallet_endpoint').replace(/^(https?:\/\/)/, '').split('/')[0]}`).withJwtToken(Token).connect();
        // const ws = Ws(`ws://localhost:3201`).withJwtToken(Token).connect();

        const wallet = ws.subscribe('wallet')

        wallet.on('ready', () => {
          // console.log('ready')
        })
        wallet.on('res', (data) => {
          // console.log(data);
          Swal.fire({
            icon: "success",
            title: `เติมเครดิตจำนวน ${data} บาท สำเร็จ.`,
            showConfirmButton: false,
            position: 'top-right',
            timer: 2000,
            toast: true,
          }).then(() => {
            axiosIns
              .get("/balance/getbalance")
              .then((response) => {
                commit('setBalance', response.data.balance)
              })
              .catch((error) => console.log(error))
          });
          
        })

        ws.on("open", () => {
          // console.log("Connection established");
        });
        ws.on("error", (error) => {
          // console.log("Connection Error", error);
        });

        ws.on("close", (event) => {
          // console.log("Connection closed", event);
        });
      }
      
    }
  },
}
