import Vue from 'vue'

// axios
import axios from 'axios'

const obj = {
  site: window.location.origin === 'http://localhost:8080' ? 'https://wallet.power77th.com' : window.location.origin,
  // site: 'https://wallet.power65th.com',
}
// const endpoint = localStorage.getItem('wallet_endpoint')
async function getEndpoint() {
  // await axios.post('https://api.powerbackend.com/api/agent/showid', obj)
  // // eslint-disable-next-line no-unused-vars
  //   .then(response => {
  //     localStorage.setItem('wallet_endpoint', response.data.wallet_endpoint)
  //     localStorage.setItem('logo_path', response.data.logo_path)
  //   })
  //   .catch(error => console.log(error))
  const { data: response } = await axios.post('https://api.powerbackend.com/api/agent/showid', obj)
  localStorage.setItem('wallet_endpoint', response.wallet_endpoint)
  localStorage.setItem('logo_path', response.logo_path)
  return response.wallet_endpoint
}
// getEndpoint()
const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // baseURL: getEndpoint(),
  // baseURL: localStorage.getItem('wallet_endpoint'),
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})
axiosIns.interceptors.request.use(
  async config => {
    if (localStorage.getItem('wallet_endpoint') === null) {
      const Endpoint = await getEndpoint()
      // eslint-disable-next-line no-param-reassign
      config.baseURL = Endpoint
    } else {
      // eslint-disable-next-line no-param-reassign
      config.baseURL = window.location.origin === 'http://localhost:8080' ? 'http://127.0.0.1:8888/api' : localStorage.getItem('wallet_endpoint')
    }
    return config
  },
  error => Promise.reject(error),
)
// axiosIns.interceptors.request.use(
//   async config => {
//     await axios.post('https://api.powerbackend.com/api/agent/showid', obj)
//     // eslint-disable-next-line no-unused-vars
//       .then(response => {
//         localStorage.setItem('wallet_endpoint', response.data.wallet_endpoint)
//         localStorage.setItem('logo_path', response.data.logo_path)
//         // eslint-disable-next-line no-param-reassign
//         config.baseURL = response.data.wallet_endpoint
//       })
//       .catch(error => console.log(error))
//     // console.log(config.headers.Authorization)

//     return config
//   },
//   error => Promise.reject(error),
// )

Vue.prototype.$http = axiosIns

export default axiosIns
